import React, { useState, useEffect, useCallback } from 'react';
import { hazardTypeMapping } from 'utils/reportTypeMapping';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, CardTitle, Row, Col, FormGroup, Label, Spinner } from 'reactstrap';
import ExploreMapBolo from '../maps/ExploreMapBolo';
import ReportsTableBolo from '../tables/ReportsTableBolo';
import DownloadButtonBasicReport from '../components/DownloadButtonBasicReport'; 
import { collection, getDocs, query } from 'firebase/firestore';
import { startOfDay, endOfDay, subMonths } from 'date-fns';
import { firestore } from '../../firebase';
import CustomizableSelect from 'views/components/CustomizableSelect';
import DateRangePickerComponent from 'views/components/DateRangePickerComponent';
import { geographicRegions } from 'utils/region';
import PieChart from 'variables/PieChart';

const BasicReportBolo = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedApp, setSelectedApp] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedInvestigation, setSelectedInvestigation] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: startOfDay(subMonths(new Date(), 12)),
    endDate: endOfDay(new Date()),
  });
  const [selectedReportTypes, setSelectedReportTypes] = useState([]); 
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [apps, setApps] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [investigations, setInvestigations] = useState([]);
  const [resetMap, setResetMap] = useState(false); 
  const [ageData, setAgeData] = useState({});
  const [genderData, setGenderData] = useState({});
  const [missingStatusData, setMissingStatusData] = useState({});

  const groupAges = (reports) => {
        const ageGroups = {
        "0-5 years": 0,
        "5-10 years": 0,
        "10-20 years": 0,
        "20-30 years": 0,
        "30-40 years": 0,
        "40-50 years": 0,
        "50-60 years": 0,
        "70+ years": 0,
        };
    
    reports.forEach((report) => {
      const age = parseInt(report.age, 10);
      if (!isNaN(age)) {
        if (age >= 0 && age <= 5) ageGroups["0-5 years"]++;
        else if (age > 5 && age <= 10) ageGroups["5-10 years"]++;
        else if (age > 10 && age <= 20) ageGroups["10-20 years"]++;
        else if (age > 20 && age <= 30) ageGroups["20-30 years"]++;
        else if (age > 30 && age <= 40) ageGroups["30-40 years"]++;
        else if (age > 40 && age <= 50) ageGroups["40-50 years"]++;
        else if (age > 50 && age <= 60) ageGroups["50-60 years"]++;
        else if (age > 70) ageGroups["70+ years"]++;
      }
    });
  
    return ageGroups;
  };
  
  const formatAgeDataForChart = (ageGroups) => {
    const labels = Object.keys(ageGroups);
    const data = Object.values(ageGroups);
  
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: [
            "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0",
            "#9966FF", "#FF9F40", "#B23CFD", "#FFA500"
          ],
          hoverBackgroundColor: [
            "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0",
            "#9966FF", "#FF9F40", "#B23CFD", "#FFA500"
          ],
        },
      ],
    };
  };
  
  const groupByField = (reports, field, map = null) => {
    const groups = {};
  
    reports.forEach((report) => {
      let key = report[field] || "Unknown";
      if (map && map[key]) {
        key = map[key].text; 
      }
      groups[key] = (groups[key] || 0) + 1;
    });
  
    return groups;
  };
  
  const formatGenderDataForChart = (genderGroups) => {
    const labels = Object.keys(genderGroups);
    const data = Object.values(genderGroups);
  
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"], 
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    };
  };
  
  const formatMissingStatusDataForChart = (missingStatusGroups) => {
    const labels = Object.keys(missingStatusGroups);
    const data = Object.values(missingStatusGroups);
  
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#9966FF"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#9966FF"],
        },
      ],
    };
  };

  const missingStatusMap = {
    found: { text: 'Person is Found' },
    deceased: { text: 'Person is Deceased' },
    coldCase: { text: 'Cold Case' },
    missing: { text: 'Person is Missing' }
  };
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      let q = query(collection(firestore, 'boloReport'));
      const querySnapshot = await getDocs(q);
  
      const reportsList = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const hazardType = hazardTypeMapping[data.hazardType] || data.hazardType;
       
        
        reportsList.push({
          documentId: doc.id,
          reportId: data.reportId,
          missingStatus: data.missingStatus,
          missingStatusText: missingStatusMap[data.missingStatus]?.text || data.missingStatus, 
          reportingApp: data.reportingApp,
          age: data.age?? 'N/A',
          gender: data.gender?? 'N/A',
          missingStatus: data.missingStatus,
          latitude: data.latitude ,
          longitude: data.longitude ,
          timeReported: data.timeReported,
          city: data.city?? 'N/A',
          state: data.state?? 'N/A',
          country: data.country?? 'N/A',
          investigation: data.investigation?? 'N/A',
          hazardType, 
          verifiedStatus: data.verifiedStatus?? 'N/A',
        });
      });
      
      setReports(reportsList);
      setFilteredReports(reportsList); 
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setIsLoading(false); 
    }
  }, []);
  

useEffect(() => {
  setResetMap(false);
  if (location.state) {
    const { country, app, region, state, city, status, investigation, dateRange } = location.state;

    setSelectedRegion(region || []); 
    setSelectedCountry(country || []);
    setSelectedState(state || []); 
    setSelectedCity(city || []); 
    setSelectedApp(app || []); 
    setSelectedStatus(status || []); 
    setSelectedInvestigation(investigation || []); 

    setSelectedDateRange({
      startDate: dateRange?.startDate || startOfDay(subMonths(new Date(), 12)), 
      endDate: dateRange?.endDate || endOfDay(new Date()), 
    });
    setResetMap(true);
  } else {

    setSelectedRegion([]);
    setSelectedCountry([]);
    setSelectedState([]);
    setSelectedCity([]);
    setSelectedApp([]);
    setSelectedStatus([]);
    setSelectedInvestigation([]);
    setSelectedDateRange({
      startDate: startOfDay(subMonths(new Date(), 12)),
      endDate: endOfDay(new Date()),
    });
    setResetMap(true);
  }

  fetchData(); 
}, [location.state, fetchData]);

const updateAvailableOptions = (filtered) => {
    const availableCountries = Array.from(new Set(filtered.map((report) => report.country)));
    setCountries(availableCountries.map((country) => ({ value: country, label: country })));

    const availableStates = Array.from(new Set(filtered.map((report) => report.state)));
    setStates(availableStates.map((state) => ({ value: state, label: state })));

    const availableCities = Array.from(new Set(filtered.map((report) => report.city)));
    setCities(availableCities.map((city) => ({ value: city, label: city })));

    const availableApps = Array.from(new Set(filtered.map((report) => report.reportingApp)));
    setApps(availableApps.map((app) => ({ value: app, label: app })));
    
    const availableStatuses = Array.from(new Set(filtered.map((report) => report.missingStatus))).map((status) => ({
    value: status, label: missingStatusMap[status]?.text || status,}));
    setStatuses(availableStatuses);

    const availableInvestigations = Array.from(new Set(filtered.map((report) => report.investigation)));
    setInvestigations(availableInvestigations.map((investigation) => ({ value: investigation, label: investigation })));
};

const applyFilters = () => {
    let filtered = [...reports];

    if (selectedRegion.length > 0) {
        filtered = filtered.filter((report) =>
            selectedRegion.some((region) =>
                geographicRegions[region]?.includes(report.country)
            )
        );
    }

    if (selectedCountry.length > 0) {
        filtered = filtered.filter((report) =>
            selectedCountry.includes(report.country)
        );
    }

    if (selectedState.length > 0) {
        filtered = filtered.filter((report) =>
            selectedState.includes(report.state)
        );
    }

    if (selectedCity.length > 0) {
        filtered = filtered.filter((report) =>
            selectedCity.includes(report.city)
        );
    }

    if (selectedApp.length > 0) {
        filtered = filtered.filter((report) =>
            selectedApp.includes(report.reportingApp)
        );
    }

    if (selectedStatus.length > 0) {
        filtered = filtered.filter((report) =>
            selectedStatus.includes(report.missingStatus)
        );
    }

    if (selectedInvestigation.length > 0) {
        filtered = filtered.filter((report) =>
            selectedInvestigation.includes(report.investigation)
        );
    }

    filtered = filtered.filter((report) => {
        if (report.timeReported && report.timeReported.seconds) {
            const reportDate = new Date(report.timeReported.seconds * 1000);
            return (
                (!selectedDateRange.startDate || reportDate >= selectedDateRange.startDate) &&
                (!selectedDateRange.endDate || reportDate <= selectedDateRange.endDate)
            );
        }
        return true;
    });

    updateAvailableOptions(filtered);
    setFilteredReports(filtered);

    const ageGroups = groupAges(filtered);
    const genderGroups = groupByField(filtered, "gender");
    const missingStatusGroups = groupByField(filtered, "missingStatus",missingStatusMap); 

    setAgeData(formatAgeDataForChart(ageGroups));
    setGenderData(formatGenderDataForChart(genderGroups));
    setMissingStatusData(formatMissingStatusDataForChart(missingStatusGroups));
};


  useEffect(() => {
    applyFilters();
  }, [reports, selectedRegion, selectedCountry, selectedState, selectedCity, selectedApp, selectedStatus, selectedInvestigation, selectedDateRange, selectedReportTypes]);

  const handleFilterChange = (filterType, selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    switch (filterType) {
      case 'region':
        setSelectedRegion(selectedValues);
        break;
      case 'country':
        setSelectedCountry(selectedValues);
        break;
      case 'state':
        setSelectedState(selectedValues);
        break;
      case 'city':
        setSelectedCity(selectedValues);
        break;
      case 'app':
        setSelectedApp(selectedValues);
        break;
      case 'status':
        setSelectedStatus(selectedValues);
        break;
      case 'investigation':
        setSelectedInvestigation(selectedValues);
        break;
      default:
        break;
    }
  };

  const handleDateRangeSelect = (startDate, endDate) => {
    setSelectedDateRange({ startDate, endDate });
  };

  const handleCheckboxChange = (updatedSelectedTypes) => {
    setSelectedReportTypes(updatedSelectedTypes);
  };

  
  //  CSV export
  const csvData = filteredReports.map(report => ({
      archived: report.archived,
      firstName: report.firstName,
      lastName: report.lastName,
      contactNumber: report.contactNumber,
      country: report.country,
      state: report.state,
      city: report.city,
      age: report.age,
      gender: report.gender,
      raceEthnicity: report.raceEthnicity,
      missingStatus: report.missingStatus,
      closedForComments: report.closedForComments,
      closedForUpdates: report.closedForUpdates,
      description: report.description,
      latitude: report.latitude,
      longitude: report.longitude,
      originalReportUserId: report.originalReportUserId,
      originalSocialMediaLink: report.originalSocialMediaLink,
      protectiveMeasures: report.protectiveMeasures,
      reportId: report.reportId,
      reportingApp: report.reportingApp,
      reportName: report.reportName,
      timeObserved: report.timeObserved && report.timeObserved.seconds ? new Date(report.timeObserved.seconds * 1000).toLocaleString() : '',
      timeReported: report.timeReported && report.timeReported.seconds ? new Date(report.timeReported.seconds * 1000).toLocaleString() : '',
      hazardType: report.hazardType,
      verificationStatus: report.verificationStatus,
      verificationTimestamp: report.verificationTimestamp && report.verificationTimestamp.seconds ? new Date(report.verificationTimestamp.seconds * 1000).toLocaleString() : '',
      verificationUpVotes: report.verificationUpVotes,
      verificationDownVotes: report.verificationDownVotes,
      verifiedByUserId: report.verifiedByUserId,
      hasPhotoAttached: report.photoUrl && report.photoUrl.length > 0 ? 'true' : 'false',
      hasDocumentAttached: report.docPdfUrl && report.docPdfUrl.length > 0 ? 'true' : 'false',

  }));

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner />
        <p>Loading data...</p>
      </div>
    );
  }

  return (

    <div >
      <Card style={{  width: '93%',marginTop: '75px', marginLeft: '40px', marginRight: '40px' }}>
        <CardBody>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label>Geographic Region</Label>
                <CustomizableSelect
                  options={Object.keys(geographicRegions).map((region) => ({
                    value: region,
                    label: region,
                  }))}
                  isMulti
                  value={selectedRegion.map((region) => ({ value: region, label: region }))}
                  onChange={(selectedOptions) => handleFilterChange('region', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Country</Label>
                <CustomizableSelect
                  options={countries}
                  isMulti
                  value={selectedCountry.map((country) => ({ value: country, label: country }))}
                  onChange={(selectedOptions) => handleFilterChange('country', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>State/Province</Label>
                <CustomizableSelect
                  options={states}
                  isMulti
                  value={selectedState.map((state) => ({ value: state, label: state }))}
                  onChange={(selectedOptions) => handleFilterChange('state', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>City</Label>
                <CustomizableSelect
                  options={cities}
                  isMulti
                  value={selectedCity.map((city) => ({ value: city, label: city }))}
                  onChange={(selectedOptions) => handleFilterChange('city', selectedOptions)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label>Investigation</Label>
                <CustomizableSelect
                  options={investigations}
                  isMulti
                  value={selectedInvestigation.map((investigation) => ({ value: investigation, label: investigation }))}
                  onChange={(selectedOptions) => handleFilterChange('investigation', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Reporting App</Label>
                <CustomizableSelect
                  options={apps}
                  isMulti
                  value={selectedApp.map((app) => ({ value: app, label: app }))}
                  onChange={(selectedOptions) => handleFilterChange('app', selectedOptions)}
                />
              </FormGroup>
            </Col>

            <Col md={3}>
            <FormGroup>
                <Label>Missing Status</Label>
                <CustomizableSelect
                options={statuses}
                isMulti
                value={selectedStatus.map((status) => ({
                    value: status,
                    label: missingStatusMap[status]?.text || status,
                }))}
                onChange={(selectedOptions) => handleFilterChange('status', selectedOptions)}
                />
            </FormGroup>
            </Col>


            <Col md={3}>
              <FormGroup>
                <Label>Date Range</Label>
                <DateRangePickerComponent
                  selectedDateRange={selectedDateRange}
                  onDateRangeSelect={handleDateRangeSelect}
                  defaultStartDate={startOfDay(subMonths(new Date(), 12))}
                  defaultEndDate={endOfDay(new Date())}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div style={{  width: '93%', height:'100%', marginTop: '40px', marginLeft: '40px', marginRight: '40px' }} >
      <ExploreMapBolo
        resetMap={resetMap}
        reports={filteredReports}
        parentSelectedTypes={selectedReportTypes}
        onCheckboxChange={handleCheckboxChange}
        //selectedCountries={selectedCountry}
      />

      </div>
      <div  style={{ width: '93%', marginLeft: '40px', marginRight: '45px' }} >
        {/* Pie Charts */}

        <Row>
            <Col md={4}>
                <PieChart
                data={ageData}
                title="Reports by Age"
                subtitle="Distribution of reports based on age groups"
                />
            </Col>
            <Col md={4}>
                <PieChart
                data={genderData}
                title="Reports by Gender"
                subtitle="Distribution of reports by gender"
                />
            </Col>
            <Col md={4}>
                <PieChart
                data={missingStatusData}
                title="Reports by Missing Status"
                subtitle="Distribution of missing status categories"
                />
            </Col>
        </Row>
        {/* Pie Charts */}
    </div>
      <div>
        <Card style={{ width: '93%', marginLeft: '40px', marginRight: '45px' }}>
          <Row>
            <Col style={{ marginTop: '50px', marginLeft: '40px', justifyContent: 'flex-start' }}>
              <CardTitle tag="h5">Reports</CardTitle>
              <Label>Backend Development</Label>
            </Col>
            <Col>
              <div style={{ marginTop: '60px', display: 'flex', justifyContent: 'flex-end' }}>
                <span style={{ marginRight: '12px', fontSize: '12px' }}>DOWNLOAD</span>
                <DownloadButtonBasicReport data={csvData} fileName="Reports" />
              </div>
            </Col>
          </Row>
          <ReportsTableBolo reports={filteredReports} />
        </Card>
      </div>

    </div>
  );
};

export default BasicReportBolo;
