import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, getDocs, query, where, updateDoc, doc, addDoc } from 'firebase/firestore';
import { firestore, storage, auth } from '../../firebase';
import { Row, Col, Button, Input, CardBody, CardTitle, CardText, Spinner, Modal, ModalHeader, ModalBody } from 'reactstrap';
import VideoPlayer from '../components/VideoPlayer';
import PhotoViewer from '../components/PhotoViewer';
import DocumentDownloader from '../components/DocumentDownloader';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { mapTypeToBombName } from '../../utils/uxotype';
import { mapTypeToName } from '../../utils/type';
import DownloadButton from '../components/DownloadButton';

const reportNameMapping = {
  uxo: 'UXOs',
  militaryActivity: 'Military Activity',
  naturalDisaster: 'Natural Disaster',
  structureHazard: 'Structure Hazard',
  terroristActivity: 'Terrorist Activity',
  criminalActivity: 'Criminal Activity',
  warCrime: 'War Crimes',
  wildlife: 'Wildlife Hazard',
  resourceScarcity: 'Resource Scarcity',
  nasa: 'NASA EONet Events',
  publicHealthHazard : 'Public Health Hazard',
  cyberThreat: 'Cyber Threat',
  transportationHazard: 'Transportation Hazard',
  environmentalHazard : 'Environmental Hazard',
  humanTrafficking: 'Human Trafficking'
};
const shortenURL = (url) => (url.length > 30 ? url.slice(0, 30) + '...' : url); 
function ReportDetails() {
    const { reportId } = useParams();
    const navigate = useNavigate();
    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const [mostRecentUpdate, setMostRecentUpdate] = useState(null); 
    const [note, setNote] = useState('');
    const [safetyStatus, setSafetyStatus] = useState('Safety conditions are currently unknown');
    const [newSafetyStatus, setNewSafetyStatus] = useState('');
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const mapRef = useRef(null);
    const [supportingUrl, setSupportingUrl] = useState('');
    const [user] = useAuthState(auth);
    const [mediaFiles, setMediaFiles] = useState([]);
    const [documentFiles, setDocumentFiles] = useState([]);
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const referrer = localStorage.getItem('referrer') || '/admin/dashboard';
    const [photoUrls, setPhotoUrls] = useState([]);
    const [videoUrls, setVideoUrls] = useState([]);
    const [documentUrls, setDocumentUrls] = useState([]);
  

  const toggleModal = () => setModal(!modal);

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Area is safe':
        return { backgroundColor: '#3DBF00', color: 'white' };
      case 'Area is not safe':
        return { backgroundColor: '#792F2A', color: 'white' };
      case 'Safety conditions are currently unknown':
        return { backgroundColor: '#FFD700', color: 'black' };
      default:
        return { backgroundColor: '#D3D3D3', color: 'black' };
    }
  };
  const getField = (updateField, reportField) => {
    return updateField !== undefined && updateField !== null && updateField !== '' ? updateField : reportField;
  };

  const formatTimeReported = (timestamp) => {
    if (!timestamp || typeof timestamp.toDate !== 'function') return 'Invalid date';
    const date = timestamp.toDate(); 
    return format(date, 'MMMM d, yyyy hh:mm a', { locale: enUS });
  };

  useEffect(() => {
    async function fetchReport() {
      setLoading(true);
      try {
        const reportQuery = query(collection(firestore, 'Reports'), where('reportId', '==', reportId));
        const reportSnapshot = await getDocs(reportQuery);
  
        if (!reportSnapshot.empty) {
          const docSnap = reportSnapshot.docs[0];
          const reportData = docSnap.data();
          const reportDocId = docSnap.id;
  
          let allPhotoUrls = reportData.photoUrl ? [...reportData.photoUrl] : [];
          let allVideoUrls = reportData.videoUrl ? [...reportData.videoUrl] : [];
          let allDocumentUrls = reportData.docPdfUrl ? [...reportData.docPdfUrl] : [];
  
          let safetyStatus = reportData.initialSafetyStatus || 'unknown';
          let mostRecentTimeReported = reportData.timeReported;
          let mostRecentUpdate = null;
  
          const updatesRef = collection(firestore, 'Reports', reportDocId, 'Update');
          const updatesSnapshot = await getDocs(updatesRef);
  
          updatesSnapshot.forEach((updateDoc) => {
            const updateData = updateDoc.data();
            if (!mostRecentUpdate || (updateData.timeReported && updateData.timeReported.toDate() > mostRecentTimeReported?.toDate())) {
              mostRecentUpdate = updateData;
              safetyStatus = updateData.safetyStatus || safetyStatus;
              mostRecentTimeReported = updateData.timeReported;
            }
            if (Array.isArray(updateData.photoUrl)) allPhotoUrls = [...allPhotoUrls, ...updateData.photoUrl];
            if (Array.isArray(updateData.videoUrl)) allVideoUrls = [...allVideoUrls, ...updateData.videoUrl];
            if (Array.isArray(updateData.docPdfUrl)) allDocumentUrls = [...allDocumentUrls, ...updateData.docPdfUrl];
          });
  
         
          const commentsRef = collection(firestore, 'Reports', reportDocId, 'Comment');
          const commentsSnapshot = await getDocs(commentsRef);
  
          commentsSnapshot.forEach((commentDoc) => {
            const commentData = commentDoc.data();
            if (Array.isArray(commentData.supportingPhotoUrl)) allPhotoUrls = [...allPhotoUrls, ...commentData.supportingPhotoUrl];
            if (Array.isArray(commentData.supportingVideoUrl)) allVideoUrls = [...allVideoUrls, ...commentData.supportingVideoUrl];
            if (Array.isArray(commentData.supportingDocuments)) allDocumentUrls = [...allDocumentUrls, ...commentData.supportingDocuments];
          });
  
          const cleanPhotoUrls = [...new Set(allPhotoUrls.filter((url) => typeof url === 'string' && url.trim() !== ''))];
          const cleanVideoUrls = [...new Set(allVideoUrls.filter((url) => typeof url === 'string' && url.trim() !== ''))];
          const cleanDocumentUrls = [...new Set(allDocumentUrls.filter((url) => typeof url === 'string' && url.trim() !== ''))];
          const safetyStatusMapping = {
            'unknown': 'Safety conditions are currently unknown',
            'safe': 'Area is safe',
            'notSafe': 'Area is not safe',
          };
          setSafetyStatus(safetyStatusMapping[safetyStatus] || safetyStatusMapping['unknown']);
          setMostRecentUpdate(mostRecentUpdate);
          setPhotoUrls(cleanPhotoUrls);
          setVideoUrls(cleanVideoUrls);
          setDocumentUrls(cleanDocumentUrls);
          setReport({ ...reportData, id: reportDocId });
        } else {
          console.error('No report found.');
          setSafetyStatus('No report found.');
        }
      } catch (error) {
        console.error('Error fetching report:', error);
      } finally {
        setLoading(false);
      }
    }
  
    fetchReport();
  }, [reportId]);
  
  
  
      const generateCsvData = () => {
        if (!report) return [];
        const combinedPhotoUrls = [...(report.photoUrl || []), ...(mostRecentUpdate?.photoUrl || []), ...photoUrls];
        const combinedVideoUrls = [...(report.videoUrl || []), ...(mostRecentUpdate?.videoUrl || []), ...videoUrls];
        const combinedDocumentUrls = [...(report.docPdfUrl || []), ...(mostRecentUpdate?.docPdfUrl || []), ...documentUrls];
      
      
        return [{
          archived: report.archived || false,
          city: getField(mostRecentUpdate?.city, report.city) || 'N/A',
          contactMethod: getField(mostRecentUpdate?.contactMethod, report.contactMethod) || 'N/A',
          contaminant: getField(mostRecentUpdate?.contaminant, report.contaminant) || 'N/A',
          country: getField(mostRecentUpdate?.country, report.country) || 'N/A',
          closedForComments: report.closedForComments || false,
          closedForUpdates: report.closedForUpdates || false,
          description: getField(mostRecentUpdate?.description, report.description) || 'N/A',
          equipment: getField(mostRecentUpdate?.equipment, report.equipment) || 'N/A',
          latitude: getField(mostRecentUpdate?.latitude, report.latitude) || 'N/A',
          longitude: getField(mostRecentUpdate?.longitude, report.longitude) || 'N/A',
          originalReportUserId: report.originalReportUserId || 'N/A',
          originalSocialMediaLink: report.originalSocialMediaLink || 'N/A',
          priority: getField(mostRecentUpdate?.priority, report.priority) || 'N/A',
          protectiveMeasures: getField(mostRecentUpdate?.protectiveMeasures, report.protectiveMeasures) || 'N/A',
          reportId: report.reportId || 'N/A',
          reportingApp: report.reportingApp || 'N/A',
          reportName: report.reportName || 'N/A',
          reportType: report.reportType || 'N/A',
          resourcesThreatened: getField(mostRecentUpdate?.resourcesThreatened, report.resourcesThreatened) || 'N/A',
          initalSafetyStatus: report.initalSafetyStatus || 'N/A',
          verifiedSafetyStatus: getField(mostRecentUpdate?.verifiedSafetyStatus, report.verifiedSafetyStatus) || 'N/A',
          size: getField(mostRecentUpdate?.size, report.size) || 'N/A',
          state: getField(mostRecentUpdate?.state, report.state) || 'N/A',
          timeObserved: report.timeObserved ? formatTimeReported(report.timeObserved) : 'N/A',
          timeReported: getField(mostRecentUpdate?.timeReported, report.timeReported) ? formatTimeReported(getField(mostRecentUpdate?.timeReported, report.timeReported)) : 'N/A',
          hazardType: getField(mostRecentUpdate?.hazardType, report.hazardType) || 'N/A',
          bombType: getField(mostRecentUpdate?.bombType, report.bombType) || 'N/A',
          unitCountry: getField(mostRecentUpdate?.unitCountry, report.unitCountry) || 'N/A',
          verificationStatus: getField(mostRecentUpdate?.verificationStatus, report.verificationStatus) || 'N/A',
          verificationTimestamp: getField(mostRecentUpdate?.verificationTimestamp, report.verificationTimestamp) ? formatTimeReported(getField(mostRecentUpdate?.verificationTimestamp, report.verificationTimestamp)) : 'N/A',
          verificationUpVotes: getField(mostRecentUpdate?.verificationUpVotes, report.verificationUpVotes) || 0,
          verificationDownVotes: getField(mostRecentUpdate?.verificationDownVotes, report.verificationDownVotes) || 0,
          verifiedByUserId: getField(mostRecentUpdate?.verifiedByUserId, report.verifiedByUserId) || 'N/A',
          withChemicals:  getField(mostRecentUpdate?.withChemicals, report.withChemicals) || 'N/A', 

          hasPhotoAttached: combinedPhotoUrls.length > 0 ? 'true' : 'false',
          hasVideoAttached: combinedVideoUrls.length > 0 ? 'true' : 'false',
          hasDocumentAttached: combinedDocumentUrls.length > 0 ? 'true' : 'false',

        }];
      };
      
    const csvData = generateCsvData();

  

  useEffect(() => {
    if (report && !loading) {
      const google = window.google;
      const latLng = new google.maps.LatLng(report.latitude, report.longitude);

      const mapOptions = {
        zoom: 5,
        center: latLng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };

      const map = new google.maps.Map(mapRef.current, mapOptions);
      
      new google.maps.Marker({
        position: latLng,
        map: map,
        title: report.city,
      });
    }
  }, [report, loading]);


  const updateSafetyStatus = async () => {
    if (!report || !report.id || newSafetyStatus === '') {
      setModalContent('Report or Report ID is missing. Please select a valid safety status.');
      toggleModal();
      return;
    }
  
    const safetyStatusMapping = {
      'Safety conditions are currently unknown': 'unknown',
      'Area is safe': 'safe',
      'Area is not safe': 'notSafe',
    };
  
    const safetyStatusValue = safetyStatusMapping[newSafetyStatus];
  
    if (safetyStatusValue === undefined) {
      setModalContent('Please select a valid safety status.');
      toggleModal();
      return;
    }
  
    try {
      const reportRef = doc(firestore, 'Reports', report.id);
      await updateDoc(reportRef, {
        updatedAt: new Date(),
      });
  
      const updatesRef = collection(firestore, 'Reports', report.id, 'HazardUpdate');
      await addDoc(updatesRef, {
        timeReported: new Date(),
        submittedUserId: user.uid,
        safetyStatus: safetyStatusValue,
      });
  
      setSafetyStatus(newSafetyStatus); 
      setNewSafetyStatus('');
      setModalContent('Your safety status update has been successfully submitted!');
      toggleModal();
    } catch (error) {
      console.error('Failed to update the safety status:', error);
      setModalContent('Failed to update the safety status.');
      toggleModal();
    }
  };
  
  const handleMediaUpload = async (files, pathPrefix) => {
    const uploadPromises = Array.from(files).map(file => {
      const storageRef = ref(storage, `${pathPrefix}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed', 
          null, 
          reject, 
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then(url => resolve({ url, name: file.name })) 
              .catch(reject);
          }
        );
      });
    });
  
    return Promise.all(uploadPromises); 
  };
  

  const submitNote = async () => {
    setAttemptedSubmit(true);
  
    if (note.trim() === '') {
      setModalContent('Please provide a comment before submitting.');
      toggleModal();
      return;
    }
  
    try {
      if (!report || !report.id) {
        setModalContent('Report ID is missing. Cannot submit comment.');
        toggleModal();
        return;
      }
  
      const mediaUrls = mediaFiles.length > 0 
        ? await handleMediaUpload(mediaFiles, `${report.reportType}Report/${report.id}`) 
        : [];
      
      const photoUrls = mediaUrls
        .filter(({ name }) => /\.(jpeg|webp|jpg|png|gif|svg)$/i.test(name))
        .map(({ url }) => url);
  
      const videoUrls = mediaUrls
        .filter(({ name }) => /\.(mp4|mov|avi|mkv)$/i.test(name))
        .map(({ url }) => url);
  

      const documentUrls = documentFiles.length > 0 
        ? (await handleMediaUpload(documentFiles, `${report.reportType}Report/${report.id}`)).map(({ url }) => url)
        : [];
  

      const commentData = {
        submittedUserId: user.uid,
        comment: note.trim(),
        timeReported: new Date(),
        archived: false,
        commentUserId: user.uid,
        supportingUrl: supportingUrl || '',
        supportingPhotoUrl: photoUrls, 
        supportingVideoUrl: videoUrls, 
        supportingDocuments: documentUrls, 
      };

      const commentRef = collection(firestore, 'Reports', report.id, 'Comment');
      await addDoc(commentRef, commentData);

      setNote('');
      setSupportingUrl('');
      setMediaFiles([]);
      setDocumentFiles([]);
      setModalContent('Your comment and materials have been successfully submitted!');
      toggleModal();
  
    } catch (error) {
      console.error('Failed to submit comment and materials:', error);
      setModalContent('Failed to submit comment and materials.');
      toggleModal();
    }
  
    setAttemptedSubmit(false);
  };
  
  
  

  const handleFileSelection = (setter, existingFiles) => (event) => {
    const newFiles = Array.from(event.target.files); 
    setter([...existingFiles, ...newFiles]);
  };
  

  const handleFileRemoval = (setter, index) => {
    setter(prevFiles => prevFiles.filter((_, i) => i !== index));
  };


  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner />
        <p style={{ marginTop: '20px', fontSize: '18px', fontWeight: '500' }}>
          Please wait, we are fetching the data for you...
        </p>
      </div>
    );
  }

  if (!report) {
    return <div>No report found.</div>;
  }

  const displayData = (data, defaultMessage = 'No information provided') => data || defaultMessage;


  return (
    <div>
      <Row className="align-items-center justify-content-between" style={{ marginTop: '70px' }}>
        <Col xs="auto">
          <button className="btn btn-info" onClick={() => navigate(referrer)} style={{ marginLeft: '20px' }}>
            Go Back
          </button>
        </Col>
        <Col xs="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '12px', fontSize: '12px' }}>DOWNLOAD</span>
          <DownloadButton
            targetId="report-details-container"
            data={csvData}
            fileName={`${report.reportType}_${report.reportId}`}
            isDataLoaded={!loading}
          />
        </Col>
      </Row>

      <div id="report-details-container" className="report-details-container">
        <Row>
          <Col lg="6" md="12" style={{ paddingRight: '15px' }}>
            <CardBody>
               <CardText style={{ marginTop: '10px', fontSize: '12px' }}>
                    <span style={{ textTransform: 'uppercase' }}>Last Update: </span> 
                {mostRecentUpdate?.timeReported
                  ? formatTimeReported(mostRecentUpdate.timeReported)
                  :  formatTimeReported(report.timeReported)}
              </CardText>
              <CardTitle style={{ marginTop: '10px' }}>
                <span className='label-text-subtitle'>
                  {reportNameMapping[report.reportType] || displayData(report.reportType)}
                </span>
              </CardTitle>
              <CardText style={{ marginTop: '10px' }}>
                <span className="label-text">Location:</span> {`${displayData(report.city)}, ${displayData(report.country)}`}
              </CardText>
              <CardText>
                <span className='label-text'> Date / Time Observed:</span> {formatTimeReported(report.timeReported)}
              </CardText>
              <CardText>
                <span className="label-text">Threat Category:</span> {reportNameMapping[report.reportType] || displayData(report.reportType)}
              </CardText>
              {report.reportType === 'uxo' && (
                <CardText><span className='label-text'> Type of Threat:</span> {mapTypeToBombName(report.bombType)}</CardText>
              )}
              {['naturalDisaster', 'structureHazard', 'terroristActivity', 'criminalActivity', 'warCrime', 'wildlife', 'resourceScarcity'].includes(report.reportType) && (
                <CardText><span className='label-text'> Type of Threat:</span> {mapTypeToName(report.hazardType)}</CardText>
              )}
              <CardText><span className='label-text'> Resources Threatened:</span> {displayData(report.resourcesThreatened)}</CardText>
              <CardText><span className='label-text'> Protective Measures:</span> {displayData(report.protectiveMeasures)}</CardText>
              <CardText>
                <span className="label-text">Social Media Links: </span>
                {(() => {
                  const isValidUrl = (url) => {
                    try {
                      new URL(url); 
                      return true;
                    } catch {
                      return false;
                    }
                  };

                  if (report.originalSocialMediaLink && isValidUrl(report.originalSocialMediaLink)) {
                    const shortenedUrl = shortenURL(report.originalSocialMediaLink);
                    return (
                      <a
                        href={report.originalSocialMediaLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#007bff', textDecoration: 'underline' }}
                      >
                        {shortenedUrl}
                      </a>
                    );
                  } else {
                    return <span>No valid link available</span>;
                  }
                })()}
           </CardText>


              <CardText><span className='label-text'> Additional Comments:</span> {displayData(report.description)}</CardText>
            </CardBody>
          </Col>

          <Col lg="6" md="12" style={{ marginTop: '60px' }}>
            <div ref={mapRef} className="report-map" style={{ height: '300px' }}></div>
          </Col>
        </Row>

        <Row style={{ marginTop: '30px' }}>
          <Col lg="6" md="12">
            <Row style={{ marginTop: '16px' }}>
              <Col lg="12" md="12">
                <CardTitle className='label-text-subtitle'>Provide Notes</CardTitle>
                <textarea
                  className="notes-input"
                  placeholder="Provide notes and details for the community regarding this specific report...."
                  value={note}
                  onChange={(e) => { setNote(e.target.value); setAttemptedSubmit(false); }}
                  style={{ borderColor: attemptedSubmit && note.trim() === '' ? 'red' : '' }}
                />
                <Input
                  type="text"
                  className="notes-input-url"
                  value={supportingUrl}
                  onChange={(e) => setSupportingUrl(e.target.value)}
                  placeholder="Add URL or Social Media Link"
                />
                <Row style={{ marginTop: '10px', marginLeft: '0px' }}>
                <Button className="btn btn-outline-default">
                <label>
                    Add Photo/Video
                    <Input
                    type="file"
                    id="mediaInput"
                    multiple
                    onChange={handleFileSelection(setMediaFiles, mediaFiles)} 
                    style={{ display: 'none' }}
                    accept="image/*,video/*"
                    />
                </label>
                </Button>
                <Button className="btn btn-outline-default" style={{ marginLeft: '10px' }}>
                    <label>
                        Add Document
                        <Input
                        type="file"
                        id="documentInput"
                        multiple
                        onChange={handleFileSelection(setDocumentFiles, documentFiles)}
                        style={{ display: 'none' }}
                        />
                    </label>
                    </Button>

                  {mediaFiles.length > 0 && (
                    <div>
                      {mediaFiles.map((file, index) => (
                        <span key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                          {file.name}
                          <i className="nc-icon nc-simple-remove" style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handleFileRemoval(setMediaFiles, index)} />
                        </span>
                      ))}
                    </div>
                  )}
                  {documentFiles.length > 0 && (
                    <div>
                      {documentFiles.map((file, index) => (
                        <span key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                          {file.name}
                          <i className="nc-icon nc-simple-remove" style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handleFileRemoval(setDocumentFiles, index)} />
                        </span>
                      ))}
                    </div>
                  )}
                </Row>

                <div className="button-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-start' }}>
                  <button className='btn btn-info' onClick={submitNote}>Add Notes</button>
                </div>
              </Col>
            </Row>

            <Modal isOpen={modal} toggle={toggleModal} className="modal-general">
              <ModalHeader className=".modal-general .modal-header" toggle={toggleModal}>Note Submission</ModalHeader>
              <ModalBody className=".modal-general .modal-body">{modalContent}</ModalBody>
            </Modal>
            <div>
              <CardText className='label-text-subtitle' style={{ marginTop: '50px' }}>Community Notes </CardText>
              <CardText>This section is coming soon ... </CardText>
            </div>
          </Col>

          <Col lg="6" md="12" style={{ marginTop: '15px' }}>
            <div>
              <CardTitle className='label-text-subtitle'>Current Safety Status:</CardTitle>
              <div className="safety-status-box" style={getStatusStyle(safetyStatus)}>
                <CardText className="text-uppercase">{safetyStatus}</CardText>
              </div>
              <CardTitle style={{ marginTop: '20px' }} className='label-text-subtitle'>Update Safety Status:</CardTitle>
              <Input
                style={{ marginTop: '20px' }}
                type="select"
                value={newSafetyStatus}
                onChange={e => setNewSafetyStatus(e.target.value)}
              >
                <option value="">Select safety status</option>
                <option value="Area is safe">Area is safe</option>
                <option value="Area is not safe">Area is not safe</option>
                <option value="Safety conditions are currently unknown">Safety conditions are currently unknown</option>
              </Input>

              <button style={{ marginTop: '20px' }} className='btn btn-info' onClick={updateSafetyStatus}>Update Status</button>

              <Modal isOpen={modal} toggle={toggleModal} className="modal-general">
                <ModalHeader className=".modal-general .modal-header" toggle={toggleModal}>Update Confirmation</ModalHeader>
                <ModalBody className=".modal-general .modal-body">{modalContent}</ModalBody>
              </Modal>
            </div>

            <Col style={{ marginTop: '20px' }}>
              <CardTitle><span className='label-text-title'>Photos</span></CardTitle>
              {photoUrls && photoUrls.length > 0 ? (
                <PhotoViewer photoUrls={photoUrls} />
              ) : (
                <CardText>No photos attached to the report.</CardText>
              )}
            </Col>

            <Col style={{ marginTop: '20px' }}>
              <CardTitle><span className='label-text-title'>Videos</span></CardTitle>
              {videoUrls && videoUrls.length > 0 ? (
                <VideoPlayer videoUrl={videoUrls} />
              ) : (
                <CardText>No videos attached to the report.</CardText>
              )}
            </Col>

            <Col style={{ marginTop: '20px', marginBottom: '20px' }}>
              <CardTitle><span className='label-text-title'>Documents</span></CardTitle>
              {documentUrls && documentUrls.length > 0 ? (
                <DocumentDownloader documentUrls={documentUrls} />
              ) : (
                <CardText>No documents attached to the report.</CardText>
              )}
            </Col>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ReportDetails;