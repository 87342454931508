import React, { useEffect, useState, useCallback, useRef } from 'react';
import { collection, query, getDocs, where, Timestamp } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { Spinner } from 'reactstrap';
import { startOfDay, endOfDay, subMonths } from 'date-fns';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import ReportModal from '../components/ReportModal';

const safetyStatusMapping = {
  unknown: 'Safety conditions are currently unknown',
  safe: 'Area is safe',
  notSafe: 'Area is not safe'
};

const getIconUrl = (reportType) => {
  let iconUrl;
  try {
    iconUrl = require(`assets/img/typeofendpoints/${reportType}.png`);
  } catch (error) {
    iconUrl = "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless_hdpi.png";
  }
  return iconUrl;
};

const formatTimeReported = (timeReported) => {
  if (!timeReported?.seconds) return 'Unknown Time';
  const date = new Date(timeReported.seconds * 1000);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) +
    ' ' + date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
};

const NasaReportsMap = ({ activeTab }) => {
  const mapRef = useRef(null);
  const [mapData, setMapData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [latestSafetyStatus, setLatestSafetyStatus] = useState(null);
  const [latestTimeReported, setLatestTimeReported] = useState(null);
  const [latestPhotoUrl, setLatestPhotoUrl] = useState([]);
  const [latestVideoUrl, setLatestVideoUrl] = useState([]);

  const defaultStartDate = startOfDay(subMonths(new Date(), 12));
  const defaultEndDate = endOfDay(new Date());
  const [selectedDateRange] = useState({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  });

  const fetchReportData = useCallback(async () => {
    setIsLoading(true);
    try {
      const q = query(
        collection(firestore, 'nasaReport'),
        where('timeReported', '>=', Timestamp.fromDate(selectedDateRange.startDate)),
        where('timeReported', '<=', Timestamp.fromDate(selectedDateRange.endDate))
      );
      const querySnapshot = await getDocs(q);
      const reports = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        latLng: { lat: parseFloat(doc.data().latitude), lng: parseFloat(doc.data().longitude) },
      }));
      setMapData(reports);
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedDateRange]);

  const fetchCompleteReportData = async (report) => {
    try {
      let photoUrl = (report.photoUrl || []).filter(Boolean);
      let videoUrl = (report.videoUrl || []).filter(Boolean);
      let safetyStatus = report.initialSafetyStatus || 'unknown';
      let timeReported = report.timeReported;

     
      const hazardUpdateSnapshot = await getDocs(collection(firestore, `nasaReport/${report.id}/HazardUpdate`));
      hazardUpdateSnapshot.forEach(subDoc => {
        const hazardData = subDoc.data();
        if (
          hazardData.safetyStatus &&
          hazardData.timeReported &&
          (!timeReported || hazardData.timeReported.seconds > timeReported.seconds)
        ) {
          safetyStatus = hazardData.safetyStatus;
          timeReported = hazardData.timeReported;
        }
      });


      const updateSnapshot = await getDocs(collection(firestore, `nasaReport/${report.id}/Update`));
      updateSnapshot.forEach(subDoc => {
        const updateData = subDoc.data();
        photoUrl = [...photoUrl, ...(updateData.photoUrl || []).filter(Boolean)];
        videoUrl = [...videoUrl, ...(updateData.videoUrl || []).filter(Boolean)];
      });
      setLatestSafetyStatus(safetyStatusMapping[safetyStatus] || safetyStatusMapping['unknown']);
      setLatestPhotoUrl(photoUrl);
      setLatestVideoUrl(videoUrl);
      setLatestTimeReported(timeReported);
      setSelectedMarker(report);
    } catch (error) {
      console.error('Error fetching complete report data:', error);
    }
  };

  const initializeMap = () => {
    if (mapRef.current && mapData.length > 0) {
      const google = window.google;
      const map = new google.maps.Map(mapRef.current, {
        zoom: 2,
        center:  { lat: 20, lng: 0 },
      });

      const markers = mapData.map(report => {
        const marker = new google.maps.Marker({
          position: report.latLng,
          title: report.city || 'Unknown Location',
          icon: {
            url: getIconUrl(report.reportType),
            scaledSize: new google.maps.Size(30, 30),
          },
        });

        marker.addListener('click', () => fetchCompleteReportData(report));

        return marker;
      });

      new MarkerClusterer({ markers, map });
    }
  };

  useEffect(() => {
    fetchReportData();
  }, [fetchReportData]);

  useEffect(() => {
    if (!isLoading && mapData.length > 0) {
      initializeMap();
    }
  }, [isLoading, mapData]);

  return (
    <div ref={mapRef} style={{ width: '100%', height: '100%' }}>
      {isLoading && <Spinner />}
      {selectedMarker && (
        <ReportModal
          isOpen={!!selectedMarker}
          toggle={() => setSelectedMarker(null)}
          report={selectedMarker}
          safetyStatus={latestSafetyStatus}
          timeReported={formatTimeReported(latestTimeReported)}
          photoUrls={latestPhotoUrl}
          videoUrls={latestVideoUrl}
          activeTab={activeTab} 
        />
      )}
    </div>
  );
};

export default NasaReportsMap;  