import React, { useState, useEffect } from "react";
import classnames from 'classnames'; 
import {
  Collapse,
  Navbar,
  Container
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../routes"; 

function AdminNavbar(props) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [color, setColor] = useState("navbar-transparent");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const updateColor = () => {
      if (window.innerWidth < 993 && collapseOpen) {
        setColor("bg-white");
      } else {
        setColor("navbar-transparent");
      }
    };

    window.addEventListener("resize", updateColor);
    return () => window.removeEventListener("resize", updateColor);
  }, [collapseOpen]);

  const getTitle = () => {
    const currentPath = location.pathname;
    const route = routes.find(route => {
      const path = route.layout + route.path;
      const regex = new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`);
      return regex.test(currentPath);
    });
    return route ? route.name : "Dashboard";
  };

  return (
    <Navbar className={classnames("navbar-absolute fixed-top", color)} expand="sm" style={{ padding: '8px 10px' }}>
      <Container fluid>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div 
            style={{ 
              width: '20px', 
              height: '20px', 
              borderRadius: '50%', 
              backgroundColor: '#66615B', 
              marginRight: '8px' 
            }} 
          />
          <div className="navbar-brand" style={{ fontSize: '22px', color: '#66615B' }}>
            {getTitle()}
          </div>
        </div>
        <Collapse className="justify-content-end" navbar isOpen={collapseOpen} />
      </Container>
    </Navbar>
  );
}

export default AdminNavbar;
