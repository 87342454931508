import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle, Input, Label, Row, Col, CardSubtitle } from 'reactstrap';
import { VectorMap as JVectorMap } from 'react-jvectormap';

const missingStatusTypes = [
  { type: 'missing', color: '#FFD700', label: 'Person is Missing' },
  { type: 'found', color: '#097969', label: 'Person is Found' },
  { type: 'deceased', color: '#D70040', label: 'Person is Deceased' },
  { type: 'coldCase', color: '#7393B3', label: 'Cold Case' }

];


const ExploreMapBolo = ({ reports = [], parentSelectedTypes, onCheckboxChange, resetMap }) => {
  const predefinedStatusTypes = missingStatusTypes.map((status) => status.type);
  const [selectedStatusTypes, setSelectedStatusTypes] = useState(predefinedStatusTypes);

  useEffect(() => {
    if (resetMap) {
      setSelectedStatusTypes(predefinedStatusTypes);
      onCheckboxChange(predefinedStatusTypes);
    }
  }, [resetMap]);

  useEffect(() => {
    if (parentSelectedTypes) {
      setSelectedStatusTypes(parentSelectedTypes);
    }
  }, [parentSelectedTypes]);

  const handleCheckboxChange = (type) => {
    let updatedTypes;
    if (selectedStatusTypes.includes(type)) {
      updatedTypes = selectedStatusTypes.filter((t) => t !== type);
    } else {
      updatedTypes = [...selectedStatusTypes, type];
    }
    setSelectedStatusTypes(updatedTypes);
    onCheckboxChange(updatedTypes);
  };

  const handleSelectAll = () => {
    if (selectedStatusTypes.length === missingStatusTypes.length) {
      setSelectedStatusTypes([]);
      onCheckboxChange([]);
    } else {
      setSelectedStatusTypes(predefinedStatusTypes);
      onCheckboxChange(predefinedStatusTypes);
    }
  };

  const markers = reports
    .filter((report) => selectedStatusTypes.includes(report.missingStatus))
    .map((report) => ({
      latLng: [report.latitude, report.longitude],
      name: `Status: ${report.missingStatusText}`,
      style: { fill: missingStatusTypes.find((status) => status.type === report.missingStatus)?.color || '#000' },
      id: report.documentId,
    }));

  const handleMarkerClick = (e, index) => {
    const reportId = markers[index].id;
    localStorage.setItem('referrer', window.location.pathname);
    window.open(`/admin/report/view/${reportId}`, '_blank');
  };

  const isSelectAllChecked = selectedStatusTypes.length === missingStatusTypes.length;

  const circleStyle = (color) => ({
    backgroundColor: color,
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    marginRight: '8px',
    marginBottom: '4px',
  });

  const reportTypeItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    padding: '3px 4px 0px 0px',
  };

  return (
    <Card className="map" style={{ overflow: 'visible', height: 'auto' }}>
      <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
        <Row>
          <Col md="4">
            <CardTitle tag="h5">Explore the Map</CardTitle>
            <CardSubtitle style={{ marginBottom: '10px', display: 'block' }}>Status Filter</CardSubtitle>
            <div style={{ display: 'flex', marginLeft: '42px', marginBottom: '10px', marginTop: '20px' }}>
              <Label style={{ color: 'black', whiteSpace: 'nowrap' }}>Select All</Label>
              <div style={{ marginLeft: 'auto' }}>
                <Input type="checkbox" onChange={handleSelectAll} checked={isSelectAllChecked} />
              </div>
            </div>
            <Col style={{ overflowY: 'scroll', maxHeight: '330px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {missingStatusTypes.map((statusType) => {
                  const hasReports = reports.some((report) => report.missingStatus === statusType.type);
                  return (
                    <div key={statusType.type} style={reportTypeItemStyle}>
                      <div style={{ display: 'flex' }}>
                        <div className="status-type-color" style={circleStyle(statusType.color)}></div>
                        <Label style={{ color: hasReports ? 'black' : 'gray' }}>{statusType.label}</Label>
                      </div>
                      <div>
                        <Input
                          type="checkbox"
                          id={`checkbox-${statusType.type}`}
                          onChange={() => handleCheckboxChange(statusType.type)}
                          checked={selectedStatusTypes.includes(statusType.type)}
                          style={{
                            marginLeft: 'auto',
                            cursor: hasReports ? 'pointer' : 'not-allowed',
                            opacity: hasReports ? 1 : 0.5,
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Col>

          {/* Map Section */}
          <Col md="8">
            <div style={{ marginTop: '30px', width: '100%', height: '100%' }}>
              <JVectorMap
                map="world_mill"
                backgroundColor="#E0E0E0"
                containerStyle={{
                  width: '100%',
                  height: '400px',
                }}
                markers={markers}
                markerStyle={{
                  initial: {
                    fill: '#FF0000',
                    stroke: '#000',
                    'stroke-width': 0.3,
                    r: 6,
                  },
                  hover: {
                    stroke: '#000',
                    'stroke-width': 2,
                    cursor: 'pointer',
                  },
                }}
                onMarkerClick={handleMarkerClick}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
      <p style={{ fontSize: '10px', marginLeft: '20px', marginTop: '20px' }}>
        If the checkboxes are grayed out, there are no reports.
      </p>
    </Card>
  );
};

export default ExploreMapBolo;
