import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { CardFooter, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';

const PieChart = ({ data, title, subtitle }) => {
  const [chartOptions, setChartOptions] = useState({
    maintainAspectRatio: false, 
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  });

  const chartContainerStyle = {
    width: '100%',
    height: '250px', 
    maxHeight: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const legendStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  };

  const legendItemStyle = {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  };

  const legendColorStyle = {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px',
  };

  const chartData = {
    ...data,
    datasets: [
      {
        ...data.datasets[0],
        backgroundColor: [
          '#FF6384',
          '#FFCE56',
          '#36A2EB',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#B23CFD',
          '#FFA500',
        ], 
      },
    ],
  };

  const legendItems = chartData.labels.map((label, index) => ({
    text: label,
    color: chartData.datasets[0].backgroundColor[index],
  }));

  return (
    <Card style={{ height: '470px'}}>
      <CardHeader>
        <CardTitle tag="h4">{title}</CardTitle>
        {subtitle && <p className="card-category">{subtitle}</p>}
      </CardHeader>
      <CardBody>
        <div style={chartContainerStyle}>
          <Pie data={chartData} options={chartOptions} />
        </div>
      </CardBody>
      <CardFooter style={legendStyle}>
        {legendItems.map((item, index) => (
          <div key={index} style={legendItemStyle}>
            <span style={{ ...legendColorStyle, backgroundColor: item.color }}></span>
            <span>{item.text}</span>
          </div>
        ))}
      </CardFooter>
    </Card>
  );
};

export default PieChart;
