import React, { useState } from 'react';
import SleuthNetReportsMap from '../maps/SleuthNetReportsMap';
import BoloReportsMap from '../maps/BoloReportsMap';
import NasaReportsMap from '../maps/NasaReportsMap';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';

const MapPage = () => {
  const [activeTab, setActiveTab] = useState('sleuthNet');

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', marginTop: '60px' }}>
      <Nav
        style={{
          padding: '0 15px',
          backgroundColor: '#f8f9fa',
          margin: '10px 20px',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          borderBottom: 'none',
        }}
      >
        {['sleuthNet', 'bolo', 'nasa'].map((tab) => (
          <NavItem key={tab} style={{ flex: 1, textAlign: 'center' }}>
            <div
              className={`custom-nav-link ${activeTab === tab ? 'active' : ''}`}
              onClick={() => toggleTab(tab)}
            >
              {tab === 'sleuthNet' && 'SleuthNet Reports'}
              {tab === 'bolo' && 'Bolo Reports'}
              {tab === 'nasa' && 'NASA Reports'}
            </div>
          </NavItem>
        ))}
      </Nav>

      <div style={{ flex: 1, overflow: 'hidden' }}>
        <TabContent activeTab={activeTab} style={{ height: '100%' }}>
          <TabPane tabId="sleuthNet" style={{ height: '100%' }}>
            <SleuthNetReportsMap activeTab={activeTab} />
          </TabPane>
          <TabPane tabId="bolo" style={{ height: '100%' }}>
            <BoloReportsMap activeTab={activeTab} />
          </TabPane>
          <TabPane tabId="nasa" style={{ height: '100%' }}>
            <NasaReportsMap activeTab={activeTab} />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default MapPage;
