import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, getDocs, query, where, updateDoc, doc, addDoc, GeoPoint } from 'firebase/firestore';
import { firestore, storage, auth } from '../../firebase';
import { Row, Col, Button, Input, CardBody, CardTitle, CardText, Spinner, Modal, ModalHeader, ModalBody } from 'reactstrap';
import VideoPlayer from '../components/VideoPlayer';
import PhotoViewer from '../components/PhotoViewer';
import DocumentDownloader from '../components/DocumentDownloader';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import DownloadButton from '../components/DownloadButton';

const reportNameMapping = {
  boloReport: 'Missing Person'
};

const shortenURL = (url) => (url.length > 30 ? url.slice(0, 30) + '...' : url);

const missingStatusMapping = {
  missing: 'Person is missing',
  deseased: 'Person is deseased',
  found: 'Person is found',
  coldCase: 'Cold case'
};

function ReportDetailsBolo() {
  const { reportId } = useParams();
  const navigate = useNavigate();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mostRecentUpdate, setMostRecentUpdate] = useState(null);
  const [note, setNote] = useState('');
  const [missingStatus, setMissingStatus] = useState('Person is missing');
  const [newMissingStatus, setNewMissingStatus] = useState('');
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const mapRef = useRef(null);
  const [supportingUrl, setSupportingUrl] = useState('');
  const [user] = useAuthState(auth);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const referrer = localStorage.getItem('referrer') || '/admin/dashboard';
  const [photoUrls, setPhotoUrls] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [documentUrls, setDocumentUrls] = useState([]);
  const [locationModal, setLocationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null); 
  const mapContainerRef = useRef(null);

  const toggleModal = () => setModal(!modal);
  const toggleLocationModal = () => setLocationModal(!locationModal);

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Person is found':
        return { backgroundColor: '#3DBF00', color: 'white' };
      case 'Person is deseased':
        return { backgroundColor: '#792F2A', color: 'white' };
      case 'Person is missing':
        return { backgroundColor: '#FFD700', color: 'black' };
      case 'Cold case':
        return { backgroundColor: '#D3D3D3', color: 'black' };
      default:
        return { backgroundColor: '#D3D3D3', color: 'black' };
    }
  };

  
  const submitNote = async () => {
    setAttemptedSubmit(true);
    if (note.trim() === '') {
      setModalContent('Please provide a comment before submitting.');
      toggleModal();
      return;
    }

    try {
      const mediaUrls = mediaFiles.length > 0
        ? await handleMediaUpload(mediaFiles, `${report.reportType}Report/${report.id}`)
        : [];
      const photoUrls = mediaUrls.filter(({ name }) => /\.(jpeg|webp|jpg|png|gif|svg)$/i.test(name)).map(({ url }) => url);
      const videoUrls = mediaUrls.filter(({ name }) => /\.(mp4|mov|avi|mkv)$/i.test(name)).map(({ url }) => url);

      const documentUrls = documentFiles.length > 0
        ? await handleMediaUpload(documentFiles, `${report.reportType}Report/${report.id}`)
        : [];

      const commentData = {
        submittedUserId: user.uid,
        comment: note,
        timeReported: new Date(),
        archived: false,
        commentUserId: user.uid,
        supportingUrl: supportingUrl || '',
        supportingPhotoUrl: photoUrls.length ? photoUrls[0] : '',
        supportingVideoUrl: videoUrls.length ? videoUrls[0] : '',
        supportingDocuments: documentUrls.length ? documentUrls.map(({ url }) => url).join(', ') : '',
        latitude: selectedLocation?.lat || null,
        longitude: selectedLocation?.lng || null,
        location: selectedLocation ? new GeoPoint(selectedLocation.lat, selectedLocation.lng) : null,
        city: selectedLocation?.city || '',
        state: selectedLocation?.state || '',
        country: selectedLocation?.country || '',
      };

      const commentRef = collection(firestore, 'boloReport', report.id, 'Comment');
      await addDoc(commentRef, commentData);
      setNote('');
      setSupportingUrl('');
      setMediaFiles([]);
      setDocumentFiles([]);
      setSelectedLocation(null);
      setModalContent('Your comment and materials have been successfully submitted!');
      toggleModal();
    } catch (error) {
      console.error('Failed to submit comment and materials:', error);
      setModalContent('Failed to submit comment and materials.');
      toggleModal();
    }
    setAttemptedSubmit(false);
  };

  const handleMediaUpload = async (files, pathPrefix) => {
    const uploadPromises = Array.from(files).map(file => {
      const storageRef = ref(storage, `${pathPrefix}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          null,
          reject,
          () => getDownloadURL(uploadTask.snapshot.ref).then(url => resolve({ url, name: file.name })).catch(reject)
        );
      });
    });
    return Promise.all(uploadPromises);
  };

  const openLocationModal = () => {
    toggleLocationModal();
    setTimeout(initializeMap, 500); 
  };

  const initializeMap = () => {
    if (!mapContainerRef.current) return;
    const google = window.google;
    const map = new google.maps.Map(mapContainerRef.current, {
      center: { lat: 37.7749, lng: -122.4194 },
      zoom: 5,
    });
    const marker = new google.maps.Marker({ map, draggable: true });
    google.maps.event.addListener(map, 'click', (event) => {
      marker.setPosition(event.latLng);
      fetchLocationData(event.latLng.lat(), event.latLng.lng());
    });
  };

  const fetchLocationData = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const addressComponents = results[0].address_components;
        const getAddressComponent = (type) => addressComponents.find(comp => comp.types.includes(type))?.long_name || '';
        const city = getAddressComponent('locality');
        const state = getAddressComponent('administrative_area_level_1');
        const country = getAddressComponent('country');
        setSelectedLocation({ lat, lng, city, state, country });
      }
    });
  };

  const getField = (updateField, reportField) => {
    return updateField !== undefined && updateField !== null && updateField !== '' ? updateField : reportField;
  };

  const formatTimeReported = (timestamp) => {
    if (!timestamp || typeof timestamp.toDate !== 'function') return 'Invalid date';
    const date = timestamp.toDate();
    return format(date, 'MMMM d, yyyy hh:mm a', { locale: enUS });
  };

  useEffect(() => {
    async function fetchReport() {
      setLoading(true);
      const reportQuery = query(collection(firestore, 'boloReport'), where('reportId', '==', reportId));
      const reportSnapshot = await getDocs(reportQuery);
  
      if (!reportSnapshot.empty) {
        const docSnap = reportSnapshot.docs[0];
        let reportData = docSnap.data();
        const reportId = docSnap.id;
        let allPhotoUrls = [...(reportData.photoUrl || [])];
        let allVideoUrls = [...(reportData.videoUrl || [])];
        let allDocumentUrls = [...(reportData.docPdfUrl || [])];
  
        let currentMissingStatus = reportData.missingStatus || 'missing';
        let mostRecentTimeReported = reportData.timeReported;
  
        const updatesRef = collection(firestore, 'boloReport', reportId, 'Update');
        const updatesSnapshot = await getDocs(updatesRef);
        let mostRecentUpdate = null;
  
        updatesSnapshot.forEach(updateDoc => {
          const updateData = updateDoc.data();
          if (!mostRecentUpdate || (updateData.timeReported && updateData.timeReported.toDate() > mostRecentTimeReported?.toDate())) {
            mostRecentUpdate = updateData;
            currentMissingStatus = updateData.missingStatus || currentMissingStatus;
            mostRecentTimeReported = updateData.timeReported;
          }
          if (updateData.photoUrl) allPhotoUrls.push(...updateData.photoUrl);
          if (updateData.videoUrl) allVideoUrls.push(...updateData.videoUrl);
          if (updateData.docPdfUrl) allDocumentUrls.push(...updateData.docPdfUrl);
        });
  
        const commentsRef = collection(firestore, 'boloReport', reportId, 'Comment');
        const commentsSnapshot = await getDocs(commentsRef);
        commentsSnapshot.forEach(commentDoc => {
          const commentData = commentDoc.data();
          if (commentData.supportingPhotoUrl) allPhotoUrls.push(commentData.supportingPhotoUrl);
          if (commentData.supportingVideoUrl) allVideoUrls.push(commentData.supportingVideoUrl);
          if (commentData.supportingDocuments) allDocumentUrls.push(...commentData.supportingDocuments.split(','));
        });
  
        setMissingStatus(missingStatusMapping[currentMissingStatus] || 'Person is missing');
        setMostRecentUpdate(mostRecentUpdate);
        setReport({ ...reportData, id: reportId });
        setPhotoUrls(allPhotoUrls);
        setVideoUrls(allVideoUrls);
        setDocumentUrls(allDocumentUrls);
      } else {
        setMissingStatus('No report found.');
      }
      setLoading(false);
    }
  
    fetchReport();
  }, [reportId]);
  

  const generateCsvData = () => {
    if (!report) return [];
    const combinedPhotoUrls = [...(report.photoUrl || []), ...(mostRecentUpdate?.photoUrl || []), ...photoUrls];
    const combinedVideoUrls = [...(report.videoUrl || []), ...(mostRecentUpdate?.videoUrl || []), ...videoUrls];
    const combinedDocumentUrls = [...(report.docPdfUrl || []), ...(mostRecentUpdate?.docPdfUrl || []), ...documentUrls];

    return [{
      archived: report.archived || false,
      city: getField(mostRecentUpdate?.city, report.city) || 'N/A',
      contactMethod: getField(mostRecentUpdate?.contactNumber, report.contactNumber) || 'N/A',
      country: getField(mostRecentUpdate?.country, report.country) || 'N/A',
      closedForComments: report.closedForComments || false,
      closedForUpdates: report.closedForUpdates || false,
      description: getField(mostRecentUpdate?.description, report.description) || 'N/A',
      latitude: getField(mostRecentUpdate?.latitude, report.latitude) || 'N/A',
      longitude: getField(mostRecentUpdate?.longitude, report.longitude) || 'N/A',
      originalReportUserId: report.originalReportUserId || 'N/A',
      originalSocialMediaLink: report.originalSocialMediaLink || 'N/A',
      priority: getField(mostRecentUpdate?.priority, report.priority) || 'N/A',
      reportId: report.reportId || 'N/A',
      reportingApp: report.reportingApp || 'N/A',
      reportName: report.reportName || 'N/A',
      reportType: report.reportType || 'N/A',
      missingStatus: report.missingStatus || 'N/A',
      state: getField(mostRecentUpdate?.state, report.state) || 'N/A',
      timeObserved: report.timeObserved ? formatTimeReported(report.timeObserved) : 'N/A',
      timeReported: getField(mostRecentUpdate?.timeReported, report.timeReported) ? formatTimeReported(getField(mostRecentUpdate?.timeReported, report.timeReported)) : 'N/A',
      verificationStatus: getField(mostRecentUpdate?.verificationStatus, report.verificationStatus) || 'N/A',
      verificationTimestamp: getField(mostRecentUpdate?.verificationTimestamp, report.verificationTimestamp) ? formatTimeReported(getField(mostRecentUpdate?.verificationTimestamp, report.verificationTimestamp)) : 'N/A',
      verificationUpVotes: getField(mostRecentUpdate?.verificationUpVotes, report.verificationUpVotes) || 0,
      verificationDownVotes: getField(mostRecentUpdate?.verificationDownVotes, report.verificationDownVotes) || 0,
      verifiedByUserId: getField(mostRecentUpdate?.verifiedByUserId, report.verifiedByUserId) || 'N/A',
      age: report.age || 'N/A',
      gender: report.gender || 'N/A',
      raceEthnicity: report.raceEthnicity || 'N/A',
      firstName: report.firstName || 'N/A',
      lastName: report.lastName || 'N/A',
      height: report.height || 'N/A',
      weight: report.weight || 'N/A',
      eyeColor: report.eyeColor || 'N/A',
      hairColor: report.hairColor || 'N/A',
      countriesVisited: report.countriesVisited || 'N/A',
      modifiedDateTime: report.modifiedDateTime || 'N/A',
      hasPhotoAttached: combinedPhotoUrls.length > 0 ? 'true' : 'false',
      hasVideoAttached: combinedVideoUrls.length > 0 ? 'true' : 'false',
      hasDocumentAttached: combinedDocumentUrls.length > 0 ? 'true' : 'false',
      archived: report.archived || 'N/A'
    }];
  };

  const csvData = generateCsvData();

  useEffect(() => {
    if (report && !loading) {
      const google = window.google;
      const latLng = new google.maps.LatLng(report.latitude, report.longitude);

      const mapOptions = {
        zoom: 5,
        center: latLng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };

      const map = new google.maps.Map(mapRef.current, mapOptions);

      new google.maps.Marker({
        position: latLng,
        map: map,
        title: report.city,
      });
    }
  }, [report, loading]);

  const updateMissingStatus = async () => {
    if (!report || !report.id || newMissingStatus === '') {
      setModalContent('Report or Report ID is missing. Please select a valid missing status.');
      toggleModal();
      return;
    }

    const mappedStatus = Object.keys(missingStatusMapping).find(
      key => missingStatusMapping[key] === newMissingStatus
    );

    if (!mappedStatus) {
      setModalContent('Please select a valid missing status.');
      toggleModal();
      return;
    }

    try {
      const reportRef = doc(firestore, 'boloReport', report.id);
      await updateDoc(reportRef, { updatedAt: new Date() });

      const statusUpdateRef = collection(firestore, 'boloReport', report.id, 'StatusUpdate');
      await addDoc(statusUpdateRef, {
        timeReported: new Date(),
        submittedUserId: user.uid,
        missingStatus: mappedStatus
      });

      setMissingStatus(newMissingStatus);
      setNewMissingStatus('');
      setModalContent('Your missing status update has been successfully submitted!');
      toggleModal();
    } catch (error) {
      console.error('Failed to update the missing status:', error);
      setModalContent('Failed to update the missing status.');
      toggleModal();
    }
  };

  const handleFileSelection = (setter, existingFiles) => (event) => {
    const newFiles = Array.from(event.target.files);
    setter([...existingFiles, ...newFiles]);
  };

  const handleFileRemoval = (setter, index) => {
    setter(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner />
        <p style={{ marginTop: '20px', fontSize: '18px', fontWeight: '500' }}>
          Please wait, we are fetching the data for you...
        </p>
      </div>
    );
  }

  if (!report) {
    return <div>No report found.</div>;
  }

  const displayData = (data, defaultMessage = 'No information provided') => data || defaultMessage;

  return (
    <div>
      <Row className="align-items-center justify-content-between" style={{ marginTop: '70px' }}>
        <Col xs="auto">
          <button className="btn btn-info" onClick={() => navigate(referrer)} style={{ marginLeft: '20px' }}>
            Go Back
          </button>
        </Col>
        <Col xs="auto" style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '12px', fontSize: '12px' }}>DOWNLOAD</span>
          <DownloadButton
            targetId="report-details-container"
            data={csvData}
            fileName={`${report.reportType}_${report.reportId}`}
            isDataLoaded={!loading}
          />
        </Col>
      </Row>

      <div id="report-details-container" className="report-details-container">
        <Row>
          <Col lg="6" md="12" style={{ paddingRight: '15px' }}>
            <CardBody>
              <CardText style={{ marginTop: '10px', fontSize: '12px' }}>
                <span style={{ textTransform: 'uppercase' }}>Last Update: </span>
                {mostRecentUpdate?.timeReported
                  ? formatTimeReported(mostRecentUpdate.timeReported)
                  : formatTimeReported(report.timeReported)}
              </CardText>
              <CardTitle style={{ marginTop: '10px' }}>
                <span className='label-text-subtitle'>
                  {reportNameMapping[report.reportType] || displayData(report.reportType)}
                </span>
              </CardTitle>
              <CardText style={{ marginTop: '10px' }}>
                <span className="label-text">Location: </span> {`${displayData(report.city)}, ${displayData(report.country)}`}
              </CardText>
              <CardText>
                <span className='label-text'> Date / Time Observed: </span> {formatTimeReported(report.timeReported)}
              </CardText>
              <CardText style={{ marginTop: '10px' }}>
                <span className="label-text"> Reporting App: </span> {displayData(report.reportingApp)}
              </CardText>
              <CardText><span className='label-text'> Person Name: </span> {displayData(report.firstName)} {displayData(report.lastName)}</CardText>
              <CardText><span className='label-text'> Gender: </span> {displayData(report.gender)}</CardText>
              <CardText><span className='label-text'> Ethnicity: </span> {displayData(report.raceEthnicity)}</CardText>
              <CardText><span className='label-text'> Age: </span> {displayData(report.age)}</CardText>
              {report.reportingApp === 'Interpol' && (
                <CardText>
                  <span className='label-text'>Additional Comments: </span>
                  {report.description && report.description.trim() ? (
                    displayData(report.description)
                  ) : (
                    <>
                      {`Hair Color: ${Array.isArray(report.hairColor) && report.hairColor.length > 0 ? report.hairColor.join(', ') : "No information provided"}. `}
                      {`Eye Color: ${Array.isArray(report.eyeColor) && report.eyeColor.length > 0 ? report.eyeColor.join(', ') : "No information provided"}. `}
                      {`Weight: ${report.weight || "No information provided"}. `}
                      {`Height: ${report.height || "No information provided"}. `}
                      {`Countries likely visited: ${report.countriesVisited || "No information provided"}.`}
                    </>
                  )}
                </CardText>
              )}
              {['NamUs'].includes(report.reportingApp) && (
                <CardText><span className='label-text'> Additional Comments:</span> {displayData(report.description)}</CardText>
                )}
              <CardText><span className='label-text'> Contact Method: </span> {displayData(report.contactNumber)}</CardText>
              <CardText><span className='label-text'> Case Link: </span>
                {report.originalSocialMediaLink ? (
                  <a
                    href={report.originalSocialMediaLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#007bff', textDecoration: 'underline' }}
                  >
                    {shortenURL(report.originalSocialMediaLink)}
                  </a>
                ) : (
                  <span>No link available</span>
                )}
              </CardText>
              
            </CardBody>
          </Col>

          <Col lg="6" md="12" style={{ marginTop: '60px' }}>
            <div ref={mapRef} className="report-map" style={{ height: '300px' }}></div>
          </Col>
        </Row>

        <Row style={{ marginTop: '30px' }}>
          <Col lg="6" md="12">
            <Row style={{ marginTop: '16px' }}>
              <Col lg="12" md="12">
                <CardTitle className='label-text-subtitle'>Provide Notes</CardTitle>
                <textarea
                  className="notes-input"
                  placeholder="Provide notes and details for the community regarding this specific report...."
                  value={note}
                  onChange={(e) => { setNote(e.target.value); setAttemptedSubmit(false); }}
                  style={{ borderColor: attemptedSubmit && note.trim() === '' ? 'red' : '' }}
                />
                <Input
                  type="text"
                  className="notes-input-url"
                  value={supportingUrl}
                  onChange={(e) => setSupportingUrl(e.target.value)}
                  placeholder="Add URL or Social Media Link"
                />
                
                <Row style={{ marginTop: '10px', marginLeft: '0px' }}>
                    <Button className="btn btn-outline-default">
                      <label>
                        Add Photo/Video
                        <Input
                          type="file"
                          id="mediaInput"
                          multiple
                          onChange={handleFileSelection(setMediaFiles, mediaFiles)}
                          style={{ display: 'none' }}
                          accept="image/*,video/*"
                        />
                      </label>
                    </Button>
                    <Button className="btn btn-outline-default" style={{ marginLeft: '10px' }}>
                      <label>
                        Add Document
                        <Input
                          type="file"
                          id="documentInput"
                          multiple
                          onChange={handleFileSelection(setDocumentFiles, documentFiles)}
                          style={{ display: 'none' }}
                        />
                      </label>
                    </Button>

                    <Button className="btn btn-outline-default" style={{ marginLeft: '10px' }} onClick={openLocationModal}>
                      <label> 
                        Select Location
                      </label>
                    </Button>
              </Row>

                  {selectedLocation && (
                    <p style={{ marginTop: '10px', marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                      <strong>Selected Location: </strong>  {selectedLocation.city}, {selectedLocation.state}, {selectedLocation.country}
                      <i 
                        className="nc-icon nc-simple-remove" 
                        style={{ marginLeft: '10px', cursor: 'pointer' }} 
                        onClick={() => setSelectedLocation(null)}
                      />
                    </p>
                  )}

                  <div style={{ marginTop: '10px', marginLeft: '15px' }}>
                    {mediaFiles.length > 0 && (
                      <div>
                        <strong>Uploaded Photos/Videos:</strong>
                        {mediaFiles.map((file, index) => (
                          <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                            <span>{file.name}</span>
                            <i
                              className="nc-icon nc-simple-remove"
                              style={{ marginLeft: '10px', cursor: 'pointer' }}
                              onClick={() => handleFileRemoval(setMediaFiles, index)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    {documentFiles.length > 0 && (
                      <div style={{ marginTop: '10px' }}>
                        <strong>Uploaded Documents:</strong>
                        {documentFiles.map((file, index) => (
                          <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                            <span>{file.name}</span>
                            <i
                              className="nc-icon nc-simple-remove"
                              style={{ marginLeft: '10px', cursor: 'pointer' }}
                              onClick={() => handleFileRemoval(setDocumentFiles, index)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                    <Modal isOpen={locationModal} toggle={toggleLocationModal} className="modal-general">
                      <ModalHeader toggle={toggleLocationModal}>Select Location</ModalHeader>
                      <ModalBody>
                        <div ref={mapContainerRef} style={{ height: '400px', width: '100%' }}></div>
                        {selectedLocation && (
                          <p style={{ marginTop: '10px' }}>
                            <strong>Selected Location:</strong> {selectedLocation.city}, {selectedLocation.state}, {selectedLocation.country}
                          </p>
                        )}
                      </ModalBody>
                    </Modal>
                          
                  <div className="button-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-start' }}>
                  <button className='btn btn-info' onClick={submitNote}>Add Notes</button>
                </div>
              </Col>
            </Row>

            <Modal isOpen={modal} toggle={toggleModal} className="modal-general">
              <ModalHeader className=".modal-general .modal-header" toggle={toggleModal}>Note Submission</ModalHeader>
              <ModalBody className=".modal-general .modal-body">{modalContent}</ModalBody>
            </Modal>
            <div>
              <CardText className='label-text-subtitle' style={{ marginTop: '50px' }}>Community Notes </CardText>
              <CardText>This section is coming soon ... </CardText>
            </div>
          </Col>

          <Col lg="6" md="12" style={{ marginTop: '15px' }}>
            <div>
              <CardTitle className='label-text-subtitle'>Current Missing Status:</CardTitle>
              <div className="safety-status-box" style={getStatusStyle(missingStatus)}>
                <CardText className="text-uppercase">{missingStatus}</CardText>
              </div>
              <CardTitle style={{ marginTop: '20px' }} className='label-text-subtitle'>Update Missing Status:</CardTitle>
              <Input
                style={{ marginTop: '20px' }}
                type="select"
                value={newMissingStatus}
                onChange={e => setNewMissingStatus(e.target.value)}
              >
                <option value="">Select missing status</option>
                {Object.values(missingStatusMapping).map(status => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </Input>

              <button style={{ marginTop: '20px' }} className='btn btn-info' onClick={updateMissingStatus}>Update Status</button>

              <Modal isOpen={modal} toggle={toggleModal} className="modal-general">
                <ModalHeader className=".modal-general .modal-header" toggle={toggleModal}>Update Confirmation</ModalHeader>
                <ModalBody className=".modal-general .modal-body">{modalContent}</ModalBody>
              </Modal>
            </div>

            <Col style={{ marginTop: '20px' }}>
              <CardTitle><span className='label-text-title'>Photos</span></CardTitle>
              {photoUrls && photoUrls.length > 0 ? (
                <PhotoViewer photoUrls={photoUrls} />
              ) : (
                <CardText>No photos attached to the report.</CardText>
              )}
            </Col>

            <Col style={{ marginTop: '20px' }}>
              <CardTitle><span className='label-text-title'>Videos</span></CardTitle>
              {videoUrls && videoUrls.length > 0 ? (
                <VideoPlayer videoUrl={videoUrls} />
              ) : (
                <CardText>No videos attached to the report.</CardText>
              )}
            </Col>

            <Col style={{ marginTop: '20px', marginBottom: '20px' }}>
              <CardTitle><span className='label-text-title'>Documents</span></CardTitle>
              <DocumentDownloader documentUrls={documentUrls} />
            </Col>

          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ReportDetailsBolo;
